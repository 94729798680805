export const routePaths = {
    index: "/",
    dashboard: "/dashboard",
    credentials: {
        root: "/credentials",
        add: "/credentials/add",
    },
    organizations: {
        root: "/organizations",
        add: "/organizations/add",
    }
};

export default routePaths;