import React, { type PropsWithChildren } from 'react';
import { Button, FluentProvider, webLightTheme } from '@fluentui/react-components';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

export const App: React.FC<PropsWithChildren> = ({ children }) => {
  const { instance: msal } = useMsal();
  const activeAccount = msal.getActiveAccount();

  const handleSignInClick = () => {
    msal.loginRedirect({
      ...loginRequest,
      prompt: "login",
    })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <BrowserRouter>
      <FluentProvider theme={webLightTheme} style={{ height: "100%" }}>
          <AuthenticatedTemplate>
            {activeAccount ? (
              children
            ) : null}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div>Credential Central</div>
              <p>Explain, explain, explain...Click Sign in to get started</p>
              <Button onClick={handleSignInClick}>Sign in</Button>
            </div>
          </UnauthenticatedTemplate>
      </FluentProvider>
    </BrowserRouter>
  );
}

export default App;
