import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";
import { Outlet, useNavigate } from "react-router-dom";

import type { PropsWithChildren } from "react";
import routePaths from "../router/routePaths";
import { useMsal } from "@azure/msal-react";

export const MainLayout: React.FC<PropsWithChildren> = () => {
    const { instance: msal } = useMsal();
    const navigate = useNavigate();

    const handleAddCredentialClick = () => {
        navigate(routePaths.credentials.add)
    };

    const handleAddOrganizationClick = () => {
        navigate(routePaths.organizations.add)
    };

    const handleSignOutClick = () => {
        msal.logoutRedirect();
    };

    const account = msal.getActiveAccount();

    return (
        <div>
            <div>
                <header>
                    <div
                        style={{
                            backgroundColor: "darkblue",
                            display: "flex",
                            justifyContent: "space-between",
                            height: 48,
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                    >
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Menu>
                                <MenuTrigger>
                                    <MenuButton>Add</MenuButton>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem onClick={handleAddCredentialClick}>Credential</MenuItem>
                                        <MenuItem onClick={handleAddOrganizationClick}>Organization</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </div>
                        {account && (
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <Menu>
                                    <MenuTrigger>
                                        <MenuButton>{account.name === "unknown" ? account.username : account.name}</MenuButton>
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </div>
                        )}
                    </div>
                </header>
            </div>
            <div>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default MainLayout;