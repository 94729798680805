import { Button, Dropdown, Label, Option, Persona, Radio, RadioGroup, Text } from "@fluentui/react-components";

import React from "react";
import entities from "../data/entities.json";

interface CredentialFormValues {
    entityId?: string;
}

type AdvanceReason = "next" | "exception";

interface StepProps {
    values?: Partial<CredentialFormValues>;
    onBack?: () => void;
    onNext?: (reason: AdvanceReason, values: Partial<CredentialFormValues>) => void;
}

export const EntitySelectionStep: React.FC<StepProps> = (props: Readonly<StepProps>) => {
    const { onBack, onNext } = props;
    const [selectedEntityId, setSelectedEntityId] = React.useState<string | undefined>();

    const handleNextClick = () => {
        onNext?.("next", {
            entityId: selectedEntityId,
        });
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Label
                    htmlFor="entity"
                    style={{
                        marginBottom: 4,
                    }}
                >   Choose the entity to be credentialed
                </Label>
                <Dropdown
                    name="entity"
                    onOptionSelect={(_e, data) => setSelectedEntityId(data.optionValue)}
                >
                    {entities.map((entity) => (
                        <Option
                            key={entity.id}
                            text={entity.name}
                            value={entity.id}
                        >
                            <Persona
                                avatar={{ color: "colorful", "aria-hidden": true }}
                                name={entity.dba ?? entity.name}
                                secondaryText={entity.name}
                            />
                        </Option>
                    ))}
                </Dropdown>
            </div>
            {onBack && <Button onClick={onBack}>Back</Button>}
            {onNext && <Button appearance="primary" onClick={handleNextClick}>Next</Button>}
        </div>
    );
};

export const EntityOwnershipCheckStep: React.FC<StepProps> = (props: Readonly<StepProps>) => {
    const { values, onNext } = props;

    if (!values?.entityId) {
        throw new Error("Entity ID is required");
    }

    const entity = entities.find((entity) => entity.id === values.entityId);
    if (!entity) {
        throw new Error("Entity not found");
    }

    const beneficialOwners = React.useMemo(() => entity.beneficialOwners ?? [], [entity?.beneficialOwners]);

    if (beneficialOwners.length === 1) {
        const beneficialOwner = beneficialOwners[0];
        return (
            <div>
                <Text>Our records indicate {beneficialOwner.name} is a <b>{beneficialOwner.ownershipPercentage * 100}%</b> ownership. Are you {beneficialOwner.name}?</Text>
                <Button onClick={() => onNext?.("exception", {})}>No</Button>
                <Button onClick={() => onNext?.("next", {})}>Yes</Button>
            </div>
        );
    } else if (beneficialOwners.length > 1) {
        return (
            <div>
                <Text>Our records indicate there are {beneficialOwners.length} beneficial owners. Please select who you are</Text>
                <RadioGroup>
                    {beneficialOwners.map((beneficialOwner) => (
                    <Radio
                        value={beneficialOwner.id}
                        label={
                            <Persona
                                avatar={{ color: "colorful", "aria-hidden": true }}
                                name={beneficialOwner.name}
                                secondaryText={`${beneficialOwner.ownershipPercentage * 100}% ownership`}
                            />
                        }
                    />
                    ))}
                    <Radio
                        value="none"
                        label="None of the above"
                    />
                </RadioGroup>
                <div>
                    <Button onClick={() => onNext?.("exception", {})}>No</Button>
                    <Button onClick={() => onNext?.("next", {})}>Yes</Button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export const EntityClaimStep: React.FC<StepProps> = (props: Readonly<StepProps>) => {
    const { onBack, onNext } = props;

    const handleNextClick = () => {
    };

    return (
        <div>
            <div>Claim business</div>
            <div>Claim business</div>
            {onBack && <Button onClick={onBack}>Back</Button>}
            {onNext && <Button appearance="primary" onClick={handleNextClick}>Next</Button>}
        </div>
    );
};

const steps = [
    {
        id: "entity_selection",
        label: "Choose an entity",
    },
    {
        id: "ownership_check",
    },
    {
        id: "ownership_link_options"
    }
] as const;

type StepId = typeof steps[number]["id"];

export const CertifyPage: React.FC = () => {
    const [currentStep, setCurrentStep] = React.useState<StepId>("entity_selection");
    const formValuesRef = React.useRef<CredentialFormValues>({});

    const renderStep = React.useCallback((step: StepId) => {
        const createIdVerificationRequest = () => {
            fetch("/api/identity_verification/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: "0193577c-4b05-7fac-a3ba-d8d096309190",
                    sandbox: true,
                }),
            })
                .then((response) => {
                    if (response.status !== 200) {
                        response.text()
                            .then((responseBody) => {
                               console.error(responseBody);
                            });
                    } else {
                        response.json()
                            .then((data) => console.log(data))
                            .catch((error) => console.error(error));
                    }
                })
                .catch((error) => console.error(error));
        };

        const handleBack = () => {
            setCurrentStep("entity_selection");
        };

        const handleNext = (reason: string, values: Partial<CredentialFormValues>) => {
            formValuesRef.current = {
                ...formValuesRef.current,
                ...values,
            };

            if (step === "entity_selection") {
                const entityId = formValuesRef.current.entityId;
                const entity = entities.find((entity) => entity.id === entityId);
                if (entity?.beneficialOwners?.length === 0) {
                    setCurrentStep("ownership_link_options");
                } else {
                    setCurrentStep("ownership_check");
                }
            } else if (step === "ownership_check") {
                if (reason === "next") {
                    createIdVerificationRequest();
                }
            }
        };

        const stepProps = {
            values: formValuesRef.current,
        }

        switch (step) {
        case "entity_selection":
            return (
                <EntitySelectionStep
                    {...stepProps}
                    onNext={handleNext}
                />
            );
        case "ownership_check":
            return (
                <EntityOwnershipCheckStep
                    {...stepProps}
                    onBack={handleBack}
                    onNext={handleNext}
                />
            );
        case "ownership_link_options":
            return (
                <EntityClaimStep
                    {...stepProps}
                    onBack={handleBack}
                />
            );
        }
    }, []);

    return (
        <div
            style={{
                display: "flex",
                columnGap: 16,
            }}
        >
            <div>
                <ul
                    style={{
                        listStyleType: "none",
                        padding: 0,
                    }}
                >
                    <li>Choose an entity</li>
                </ul>
            </div>
            <div>
                {renderStep(currentStep)}
            </div>
        </div>
    );
};

export default CertifyPage;

// Find business
// Claim business
// Upload business registration documents
//