import { Button, Card, CardFooter, CardHeader, Text } from "@fluentui/react-components";

import React from "react";
import catalog from "../data/catalog.json";
import messages from "../data/messages.json";
import routePaths from "../router/routePaths";
import { useNavigate } from "react-router-dom";

interface AvailableProgramCardProps {
    credential: typeof catalog.credentials[0];
}

const AvailableProgramCard = React.forwardRef<HTMLElement, AvailableProgramCardProps>((props, _forwardedRef) => {
    const { credential } = props;
    const navigate = useNavigate();

    const handleApplyClick = React.useCallback(() => {
        navigate(routePaths.credentials.add);
    }, [navigate]);

    return (
        <Card
            style={{
                maxWidth: 368,
            }}
        >
            <CardHeader header={<Text weight="semibold">{credential.name}</Text>} />
            <Text wrap>{credential.description}</Text>
            <CardFooter>
                <Button
                    appearance="primary"
                    onClick={handleApplyClick}
                >
                    Apply
                </Button>
            </CardFooter>
        </Card>
    );
});

export const DashboardPage: React.FC = () => {
    return (
        <div
            style={{
                columnGap: 16,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    columnGap: 16,
                    display: "flex",
                }}
            >
                <Card>
                    <CardHeader header={<Text weight="semibold">Credentials</Text>} />
                    <Text size={800}>0</Text>
                </Card>
                <Card>
                    <CardHeader header={<Text weight="semibold">My organizations</Text>} />
                    <Text size={800}>0</Text>
                </Card>
                <Card>
                    <CardHeader header={<Text weight="semibold">Messages</Text>} />
                    <Text size={800}>{messages.length}</Text>
                </Card>
                <Card>
                    <CardHeader header={<Text weight="semibold">Tasks</Text>} />
                    <Text size={800}>0</Text>
                </Card>
            </div>
            <div
                style={{
                    columnGap: 16,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Text weight="semibold">Programs</Text>
                <div
                    style={{
                        columnGap: 16,
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    {catalog.credentials.map((credential) => (
                        <AvailableProgramCard
                            credential={credential}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;