import { useEffect, useRef } from "react";

export const EntityClaimPage: React.FC = () => {
    const stripeRef = useRef(window.Stripe('pk_test_zStnTt4b12L0s6cI31yI64EG'));

    useEffect(() => {
        const stripe = stripeRef.current;

        const openStripeCollection = ({ clientSecret }: { clientSecret: string }) => {
            stripe.collectFinancialConnectionsAccounts({
                clientSecret,
            });
        };

        fetch("/api/bank_verification/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: "0193577c-4b05-7fac-a3ba-d8d096309190",
                entityId: "unknown",
            }),
        })
        .then((response) => {
            response.json()
                .then((data) => {
                    console.log(data);
                    openStripeCollection(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    return (
        <div>

        </div>
    );
};

export default EntityClaimPage;